@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap");
body {
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}

li {
  list-style: none;
  color: #fff;
}

html a {
  text-decoration: none;
  font-size: 1.02vw;
  color: #fff;
  transition: all 0.3s ease-in-out;
}
html a:hover {
  color: #ed174b !important;
}

img {
  max-width: 100%;
}

ul {
  margin: 0px;
  padding: 0px;
}

h3 {
  font-size: 2.63vw;
  font-weight: 400;
}

p {
  font-size: 18px;
}

.submit-error {
  color: #ed174b;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}

.submit-success {
  width: -moz-fit-content;
  width: fit-content;
  background: #ddd;
  padding: 10px 20px;
  border-radius: 8px;
  background: rgb(230, 255, 217);
  background: linear-gradient(0deg, rgb(230, 255, 217) 0%, rgb(187, 246, 157) 100%);
  border: 1px solid rgba(9, 173, 9, 0.15);
}
.submit-success p {
  font-size: 16px;
  font-weight: 500;
  color: rgb(9, 173, 9);
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 0px;
  margin-bottom: 5px;
}
.submit-success span {
  font-size: 12px;
  color: #0a3101;
  font-weight: 500;
}
.submit-success img {
  width: 30px;
}

header {
  border-bottom: 4px solid #ed174b;
  background: #1c1c1c;
  padding: 10px 40px;
}

.logoWrap img {
  width: 23vw;
}

.appMenuList {
  flex-direction: row;
  display: flex;
  gap: 150px;
  text-align: center;
  justify-content: center;
}
.appMenuList li a {
  font: 800 16px "Roboto", sans-serif;
  color: #fff;
  padding: 20px 10px;
  border-top: 5px solid #1c1c1c;
}
.appMenuList li .active {
  border-color: #fff;
}

header li {
  font-weight: 800;
}
header li span {
  padding-right: 10px;
}
header li a {
  color: #fff;
  font-size: 18px;
  font-weight: 800;
}

.rightHeader {
  text-align: right;
}

.section_first {
  height: 70vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}
.section_first .column_first {
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(../images/flag.webp);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 15px;
}
.section_first .column_first h1 {
  font-size: 4.97vw;
  color: #fff;
  display: grid;
}
.section_first .column_first h1 span {
  font-weight: 100;
}
.section_first .column_second {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ed174b;
}
.section_first .column_second img {
  width: 29vw;
}

.logoSection {
  background: #1c1c1c;
  z-index: 222;
  position: relative;
}
.logoSection .col-md-4 {
  text-align: left;
}
.logoSection .col-md-4 img {
  width: 100%;
}

.what_we_do {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
}

.what_we_do_box {
  display: flex;
  align-items: center;
  align-items: center;
  background: #ed174b;
  height: 100%;
  padding: 40px 40px;
}
.what_we_do_box .text_box {
  height: auto;
  background: #fff;
  border-radius: 0.25rem;
  padding: 40px;
}
.what_we_do_box .text_box h3 {
  font-size: 2.63vw;
  font-weight: 400;
}
.what_we_do_box .text_box p {
  font-size: 18px;
}

.img_box {
  text-align: center;
  background: #E5EFF9;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}
.img_box img {
  width: 40vw;
}

.aboutUs .img_box {
  background: #1c1c1c;
}
.aboutUs .img_box {
  padding: 5vw 7.6vh;
}

.brand_logo {
  padding: 40px 0px;
}

.our_team_red_box {
  height: 100%;
  background: #ed174b;
  padding: 40px;
}
.our_team_red_box h3 {
  color: #fff;
}
.our_team_red_box .lets_talk {
  font-size: 1.24vw;
  position: relative;
  padding: 30px 22px 25px;
  background-color: #fff;
  z-index: 1;
  -webkit-clip-path: polygon(0 31%, 100% 31%, 100% 75%, 18% 75%, 0 88%, 0 75%, 0 75%);
          clip-path: polygon(0 31%, 100% 31%, 100% 75%, 18% 75%, 0 88%, 0 75%, 0 75%);
  font-weight: 500;
}

.title_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
}

.our_team {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://greatbritish.b-cdn.net/wp-content//uploads/2020/07/slide_3-1.jpg);
  background-position: 15vw 90%;
  background-size: cover;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 1.5rem;
  height: 100%;
}
.card .profile_img img {
  border-radius: 50%;
  max-width: 100%;
  width: 100%;
}
.card .profile_text h3 {
  color: #1c1c1c;
  width: 10.68vw;
}
.card .card_text p {
  padding-top: 1.5rem;
  font-size: 1.02vw;
}

.extra-width {
  width: 65vw;
}

footer {
  padding: 30px 40px 16px 40px;
  background-color: #1c1c1c;
}
footer .footer_details {
  padding-bottom: 30px;
}
footer .footer_details ul li {
  padding-top: 1.5rem;
}
footer .footer_details ul li a {
  color: #fff;
  font-size: 1.31vw;
  font-weight: 600;
}
footer .footer_details ul li svg {
  margin-right: 10px;
}
footer .footer_right {
  text-align: right;
}
footer .footer_right .social_media_links {
  display: flex;
  justify-content: right;
  gap: 10px;
  align-items: stretch;
}
footer .footer_right .social_media_links svg {
  stroke: none;
  width: 25px;
  height: 25px;
  border-radius: 2px;
  height: 100%;
}
footer .footer_right .term_and_condition {
  margin-top: 40px;
  display: flex;
  justify-content: right;
  gap: 10px;
}
footer .footer_right .term_and_condition li {
  font-size: 1.02vw;
}

.we_supply {
  padding: 4.8rem 40px;
  padding-bottom: 40px;
}
.we_supply .heading_panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.we_supply .slide_arrow {
  background: #1c1c1c;
  height: 46px;
  width: 46px;
  text-align: center;
  border: none;
  color: #fff;
  margin: 0px 5px;
  font-family: "FontAwesome";
}
.we_supply .slide_arrow i {
  font-style: normal;
}
.we_supply .slide_arrow:hover {
  background-color: #2870ac;
}

.about_supply .we_supply {
  background: #000;
}
.about_supply .we_supply h3 {
  color: #fff;
}

.we_supply_slider .card {
  text-align: center;
  background: #e5eff9;
  border: none;
  position: relative;
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
  height: -moz-fit-content;
  height: fit-content;
}
.we_supply_slider .card .card_image {
  position: relative;
  top: 3.6vw;
}
.we_supply_slider .card .card_title {
  color: #212529 !important;
  font-size: 16px;
  margin-top: 20px;
  background: #fff;
  padding-bottom: 40px;
  margin-bottom: 0px;
  padding-top: 70px;
  font-weight: 500;
  font-family: "Space Grotesk", sans-serif;
}
.we_supply_slider .card .card_title a {
  color: #007bff;
}

.about_panel {
  padding: 0px 40px;
  height: 100vh;
}

.page_breadcrumbs {
  height: 36vw;
}
.page_breadcrumbs .row {
  height: 100%;
}
.page_breadcrumbs .text_panel_breadcrumbs {
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url(https://greatbritish.b-cdn.net/static/css/img/flag.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0px 40px;
  padding-top: 6vw;
}
.page_breadcrumbs .text_panel_breadcrumbs h1 {
  font-size: 4.97vw;
  color: #fff;
  display: grid;
  font-weight: 400;
}
.page_breadcrumbs .text_panel_breadcrumbs_image {
  height: 100%;
}
.page_breadcrumbs .text_panel_breadcrumbs_image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.about_breadcrumbs {
  height: 100vh;
}

.contact_form_img {
  text-align: center;
}
.contact_form_img img {
  width: 35vw;
}

.contact_form h3 {
  margin-bottom: 30px;
}

.get_in_touch {
  padding: 40px;
  margin-bottom: 3rem;
}
.get_in_touch .footer_details {
  background-color: #ed174b;
  padding: 1.5rem;
}
.get_in_touch .footer_details ul li {
  margin-bottom: 1rem;
}
.get_in_touch .footer_details ul li svg {
  margin-right: 1rem;
}
.get_in_touch .footer_details ul li a {
  font-size: 1.31vw;
}

.call_panel {
  background: #1c1c1c;
  padding: 40px;
}
.call_panel .row {
  background-color: #e5eff9;
  border-radius: 10px;
  padding: 22px;
}
.call_panel .call_btn {
  font-size: 1.5rem;
  font-weight: 500;
  background: #2870ac;
  color: #fff;
  padding: 1rem;
  border: none;
}
.call_panel .call_btn svg {
  margin-right: 10px;
}

.about_callpanel .call_panel {
  background: #fff;
}

.text_contact {
  display: flex;
  align-items: center;
  gap: 15px;
}
.text_contact p {
  font-size: 1.5rem;
  margin: 0px;
  font-weight: 500;
  font-family: roboto;
}

.input_group {
  margin-bottom: 20px;
}
.input_group textarea {
  height: 288px;
}
.input_group button {
  max-width: 150px;
  background: #ed174b;
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
  text-align: center;
  border: none;
  padding: 10px 40px;
  float: right;
}

.input_design {
  width: 100%;
  padding: 10px;
  border: 1px solid #707070;
  outline: none;
}
.input_design::-moz-placeholder {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  font-family: roboto;
}
.input_design::placeholder {
  text-transform: capitalize;
  font-size: 1rem;
  font-weight: 600;
  font-family: roboto;
}

.react-multi-carousel-track {
  gap: 20px;
  padding-bottom: 30px !important;
  overflow: visible !important;
}

.react-multi-carousel-dot--active button {
  background: rgba(0, 0, 0, 0.5) !important;
}

.react-multi-carousel-dot-list {
  margin-top: 12px;
}

.react-multi-carousel-dot button {
  border: none !important;
  background: rgba(0, 0, 0, 0.2) !important;
}

li.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: rgba(0, 0, 0, 0.5) !important;
}

.term_condition_text_box {
  padding: 50px 40px;
}
.term_condition_text_box h3 {
  font-size: 28px;
  margin-top: 20px;
}

.wp-block-table {
  font-size: 1.125rem;
}
.wp-block-table td {
  border: 1px solid;
  padding: 0.5em;
}

.error {
  height: 100%;
  background: #ed174b;
  font-family: roboto;
  color: #fff;
}
.error a {
  color: #fff;
  text-decoration: underline;
}

.please_wait {
  padding: 4rem 0rem;
  text-align: center;
}
.please_wait span {
  color: #ed174b;
  font-size: 12px;
  font-weight: 500;
  margin-top: 10px;
}

.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #ed174b;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
  margin: 0px auto;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
.skeleton-loader {
  width: 27vw;
  height: 26.979vw;
  border-radius: 50%;
}

@keyframes pulse {
  from {
    opacity: 0.6;
  }
  to {
    opacity: 1;
  }
}
.loading-animation {
  overflow: hidden;
  background: #f3698b;
}

.loading-animation::before {
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  animation: loading 1s infinite;
  background: linear-gradient(to right, transparent, #fa8aa6, transparent);
}

@keyframes loading {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.mobileHeader {
  display: none;
}

@media screen and (max-width: 767px) {
  header {
    padding: 10px 10px;
  }
  .mobileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .desktopHeader {
    display: none;
  }
  .mehuToggleButton {
    display: block;
    row-gap: 10px;
    width: -moz-fit-content;
    width: fit-content;
  }
  .menuLink {
    display: none;
  }
  .menuLink li a {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-weight: 500;
    padding: 20px 10px;
    display: block;
  }
  .logoWrap {
    width: 60%;
    float: right;
  }
  .logoWrap img {
    width: 100%;
  }
  .logoWrapHidden {
    display: block;
  }
  .skeleton-loader {
    width: 80%;
    height: 307px;
  }
  h3 {
    font-size: 26px;
  }
  .section_first .column_second img {
    width: 80%;
    margin: 0px auto;
  }
  .section_first .column_first {
    justify-content: center;
  }
  .section_first .column_first h1 {
    font-size: 58px;
    justify-content: center;
    text-align: center;
  }
  .what_we_do_box {
    padding: 10px 10px;
  }
  .img_box img {
    width: 100%;
  }
  .react-multi-carousel-track {
    gap: 0;
  }
  .we_supply_slider .card {
    box-shadow: none !important;
  }
  .we_supply_slider .card .card_title {
    padding-top: 41px;
    padding-bottom: 22px;
  }
  .we_supply_slider .card .card_title a {
    font-size: 17px;
  }
  .extra-width {
    width: inherit;
    display: grid;
    row-gap: 10px;
  }
  .extra-width .profile_text {
    text-align: center;
  }
  .extra-width .profile_text h3 {
    width: -moz-fit-content;
    width: fit-content;
    display: block;
    margin: 0px auto;
    margin-top: 10px;
  }
  .logoSection {
    padding: 10px 0px;
  }
  .profile_img img {
    height: 137px;
    width: 137px !important;
    margin: 0px auto;
    display: block;
  }
  .card .card_text {
    text-align: center;
  }
  .card .card_text p {
    font-size: 12px;
    line-height: 24px;
  }
  .our_team_red_box {
    padding: 10px;
  }
  .our_team_red_box .lets_talk {
    display: none;
  }
  .our_team_red_box .title_row {
    margin-bottom: 2rem;
    text-align: center;
    margin-top: 2rem;
    text-align: center;
    justify-content: center;
  }
  footer .footer_details ul li a {
    font-size: 14px;
  }
  footer .footer_right .social_media_links {
    justify-content: start;
  }
  footer .footer_right .term_and_condition {
    justify-content: start;
  }
  footer .footer_right .term_and_condition li {
    font-size: 12px;
  }
  footer .footer_right .term_and_condition li a {
    font-size: 12px;
  }
  .page_breadcrumbs {
    height: 50vh;
  }
  .page_breadcrumbs .text_panel_breadcrumbs {
    text-align: center;
    border-bottom: 10px solid #ed174b;
    display: flex;
    align-items: center;
  }
  .page_breadcrumbs .text_panel_breadcrumbs h1 {
    font-size: 58px;
  }
  .text_panel_breadcrumbs_image {
    display: none;
  }
  .about_breadcrumbs {
    height: 50vh;
    overflow: hidden;
  }
  .text_contact {
    display: block;
    text-align: center;
  }
  .text_contact p {
    font-size: 16px;
    margin: 15px 0px;
  }
  .call_panel .call_btn {
    font-size: 16px;
    padding: 10px 16px;
    display: block;
    margin: 0px auto;
  }
  .what_we_do_box .text_box {
    text-align: center;
  }
  .what_we_do_box .text_box h3 {
    font-size: 26px;
  }
  .what_we_do_box .text_box p {
    font-size: 13px;
    line-height: 24px;
  }
  .contact_form_img img {
    width: 100%;
  }
  .get_in_touch {
    margin-bottom: 1rem;
  }
  .get_in_touch .footer_details ul li {
    display: flex;
    padding: 0px;
    gap: 10px;
  }
  .get_in_touch .footer_details ul li svg {
    margin: 0px;
    width: 20px;
  }
  .get_in_touch .footer_details ul li a {
    font-size: 16px;
  }
  .captachaPanel {
    width: 100%;
    float: left;
  }
}/*# sourceMappingURL=main.css.map */